import{useState,useEffect} from 'react'
//import ReactDOM from 'react-dom'
import useHttp from './hooks/useHttp'
import WhiteTileWrapper from './Containers/WhiteTileWrapper'

function App() {
  const [skuArr,setSkuArr] = useState()
  const [skusInfo,setSkusInfo] = useState()
  const [rowNumber,setRowNumber] = useState()
  const [pageUrl,setPageUrl] = useState()
  const [wrapperInfo,setWrapperInfo] = useState()
  const {error,sendRequest,isLoading } = useHttp()
 
  

 //console.log('window url:',window.location.pathname)
  // change it *************************************** 
const page_url =
window.location.pathname !== '' && window.location.pathname !== '/'
  ? window.location.pathname.slice(1)
  : 'homepage'


//const testSkuData = 'https://homepage-white-tiles-test.firebaseio.com/data.json' // TEST
//const testHomepageData = `https://homepage-white-tiles-test.firebaseio.com/data/homepage/${page_url}.json` //TEST

const prodSkuData = 'https://homepage-white-tiles-default-rtdb.firebaseio.com/data.json' //PROD
const prodHomepageData = `https://homepage-white-tiles-default-rtdb.firebaseio.com/data/homepage/${page_url}.json` //PROD

const validSkus = async data => {
 //console.log('fetch from sku list:',data)
  let sku_arr_01 =  []

  const arrayOfData1 = Object.values(data.whiteTilesData)
  const filtered_arrayOfData = arrayOfData1.filter(el => el !== null);
  //console.log('filtered_arrayOfData:',filtered_arrayOfData)

  const newArrayOfData1 = filtered_arrayOfData.map(p =>
    p.assigned_page === ''
      ? { ...p, assigned_page: 'homepage' }
      : p)
      setSkusInfo(newArrayOfData1)

      newArrayOfData1.map(d => {
        const assigned_pageArr = d.assigned_page.split(',')
        //console.log('assigned_pageArr:',assigned_pageArr,assigned_pageArr.indexOf(page_url) > -1,page_url,d.assigned_page)
      
          if(d.assigned_page === page_url || assigned_pageArr.indexOf(page_url) > -1){
            //console.log('push this SKU',d,d.sku)
            sku_arr_01.push(d.sku)  
          }
      })
      setSkuArr(sku_arr_01)
  }
  const homepageData = data => {
    setWrapperInfo(data)
    setRowNumber(data.row_number)
  }

  useEffect(() => {
    sendRequest({ url: prodSkuData },validSkus)
    sendRequest({ url: prodHomepageData },homepageData)
    setPageUrl(page_url)
  }, []) 

    return (
      !error && skuArr && skuArr.length > 0 ? 
      <>
      <WhiteTileWrapper skuArr={skuArr} skusInfo={skusInfo} wrapperInfo={wrapperInfo} loading={isLoading} pageUrl={pageUrl} rowNumber={rowNumber}/>
    {/* <div>
        {ReactDOM.createPortal(<WhiteTileWrapper skuArr={skuArr} skusInfo={skusInfo} wrapperInfo={wrapperInfo} loading={isLoading} pageUrl={page_url}/>,document.getElementById('whiteTiles2'))}
    </div> */}
    </>
    : null
    );
  }

export default App;
